import Header from "./Header";
import { Outlet } from 'react-router-dom';
import Container from "./UI/layout/Container";
import SearchForm from "./UI/search/SearchForm";
import { useState } from 'react';

export default function Layout() {
    const [searchOpen, setSearchOpen] = useState(false);

    return (
        <Container className="mx-auto">
            <Header searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
            <Outlet />
            <SearchForm searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        </Container>
    )
}