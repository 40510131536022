import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBook } from '../api/books';
import './Book.css';
import { Link } from 'react-router-dom';
import isLoggedIn from '../auth/auth';
import Buttons from './UI/impression/Buttons';
import { didUserVote, getBookVotes, doUserVote } from '../api/impressions';

export default function Book() {
    const params = useParams();
    const { id } = params;
    const [book, setBook] = useState({});
    const [bookId, setBookId] = useState('');
    const [voteUp, setVoteUp] = useState(0);
    const [voteDown, setVoteDown] = useState(0);
    const [didVote, setDidVote] = useState(false);

    const canUserVote = () => {
        if(!isLoggedIn()) {
            setDidVote(true);
        }
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.readerId;
        didUserVote(userId, id).then(data => {
            setDidVote(data.didVote);
        });
    };

    const handleUserVote = (liked = 0) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.readerId;
        const data = {
            readerId: parseInt(userId, 10),
            bookId: parseInt(id, 10),
            liked: parseInt(liked, 10)
        };
        return doUserVote(data);
    };

    const handleLikeButton = () => {
        if(didVote) {
            return false;
        }
        setVoteUp(previousValue => {
            return previousValue + 1;
        });
        handleUserVote(1).then(res => {
            setDidVote(true);
        });
    };

    const handleDislikeButton = () => {
        if(didVote) {
            return false;
        }
        setVoteDown(previousValue => {
            return previousValue + 1;
        });
        handleUserVote(0).then(res => {
            setDidVote(true);
        });
    };

    useEffect(() => {
        setBookId(id);
        getBook(id).then(data => {
            setBook(data);
            document.title = data.name;
        });
        getBookVotes(id).then(results => {
            setVoteUp(parseInt(results.likes, 10));
            setVoteDown(parseInt(results.dislikes, 10));
        });
        canUserVote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <article className="book-details max-w-screen-lg mx-auto">
          <div className="bg-white shadow-md border border-gray-200 rounded-lg mb-5"> 
            <figure>
                 <img src={book.image} className="w-full" alt={book.name} />
            </figure>
            <div className="p-5">
                <h1 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                    {book.name}
                </h1>
                <p className="font-normal text-gray-700 mb-3">
                    By {book.author}, Pages: {book.pagecount}, Price: $ {book.price}
                </p>
                { isLoggedIn() && <Buttons voteUp={voteUp} voteDown={voteDown} like={handleLikeButton} dislike={handleDislikeButton} /> }
                <p className="mt-4">
                    { isLoggedIn() ?
                    <Link to={`/order/${bookId}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Rent or Buy</Link>
                    : <Link to={`/login`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Login</Link>
                    }
                </p>
            </div>
           </div>  
        </article>
    );
}