import axios from 'axios';
import config  from './config';

export async function getCountries() {
     try {
        const req = await axios.get(`${config.URL}countries`);
        return req.data;
    } catch(err) {
        return [];
    }
}