import { useState, useRef } from "react"
import { login } from '../api/readers';
import Container from './UI/layout/Container';
import Form from './UI/forms/Form';
import Row from './UI/forms/Row';
import Label from './UI/forms/Label';
import Submit from './UI/forms/Submit';
import ErrorMessage from "./UI/forms/ErrorMessage";

export default function Login() {
    const usernameRef = useRef();
    const passwordRef = useRef();
    const [loginError, setLoginError] = useState(false);

    document.title = 'Login';


    const handleLoginForm = (e) => {
        e.preventDefault();
        setLoginError(false);
        const credentials = { username: usernameRef.current.value, password: passwordRef.current.value };
        login(credentials).then(resp => {
            if(!resp.success) {
                return setLoginError(true);
            }
            const { user, token } = resp;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            window.location = '/profile';
        });
    };

    return (
        <Container>
            <Form onSubmit={handleLoginForm}>
                <Row>
                    <Label>
                        Email
                    </Label>
                    <input ref={usernameRef} type="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </Row>
                <Row>
                    <Label>
                        Password
                    </Label>
                    <input ref={passwordRef} type="password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </Row>
                <Row>
                    <Submit>Sign In</Submit>
                </Row>
               {loginError ? <ErrorMessage text="Invalid Login" /> : null }
            </Form>
        </Container>    
    );
}