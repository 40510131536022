import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBooksByAuthor } from '../api/books';
import { getAuthor } from '../api/authors';
import BookListItem from './BookListItem';
import './Author.css';

export default function Author() {
    const params = useParams();
    const { id } = params;
    const [books, setBooks] = useState([]);
    const [authorName, setAuthorName] = useState('');

    useEffect(() => {
        getAuthor(id).then(data => {
            setAuthorName(data.name);
            document.title = data.name;
        });
        getBooksByAuthor(id).then(resp => {
            setBooks(resp);
        });
    }, [id]);

    return (

        <div className="author mt-6 mb-6">
            <h1 className="font-bold text-lg mb-6">
                { authorName }
            </h1>
            <div className="container mx-auto">
                {books.map((book) => (
                     <BookListItem item={book} key={book.id} />
                ))}
            </div>
        </div>    

    );
}