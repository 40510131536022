import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import isLoggedIn from '../auth/auth';
import { getBook } from '../api/books';
import { create } from '../api/rentals';
import Cart from './UI/cart/Cart';
import CartBody from './UI/cart/CartBody';
import CartHeader from './UI/cart/CartHeader';
import CartSummary from './UI/cart/CartSummary';
import Column from './UI/layout/Column';
import './Order.css';


export default function Order() {
    const params = useParams();
    const { id } = params;
    const [book, setBook] = useState({});
    const [bookId, setBookId] = useState('');

    const handleRedirect = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            bookId,
            readerId: user.readerId
        };
        create(data).then(resp => {
            if(resp.created) {
                window.location = '/profile';
            }
        });
    };

    useEffect(() => {
        document.title = 'Order';
        setBookId(id);
        getBook(id).then(data => {
            setBook(data);
        });
    }, [id]);
    return (
        <>
             {
            isLoggedIn() ? 

            <Cart>
                <Column width="3/4" className="bg-white px-10 py-10 cart-main">
                     <CartHeader title={book.name} />
                     <CartBody books={[book]} />
                </Column>
                <Column width="1/4" className="px-8 py-10 cart-summary-wrap">
                    <CartSummary count="1" price={book.price} total={book.price} handleRent={handleRedirect} handleBuy={handleRedirect} />
                </Column>
            </Cart>   
            
            : <Navigate to={`/login`} replace />
            }
        </>
       
    );
}