import { formatDate } from "../utils/dates";
import TableList from "./UI/layout/TableList";
import TableListHeader from "./UI/layout/TableListHeader";
import TableListRow from "./UI/layout/TableListRow";

export default function UserOrders({ orders }) {
    const headerCells = [
        {
            size: '2',
            text: 'Book'
        },
        {
            size: '4',
            text: 'Date'
        },
        {
            size: '4',
            text: 'Total'
        }
    ];
    return (
      <TableList>  
            <TableListHeader title="Orders" cells={headerCells} />

        {
            orders.map((order) => (
                <TableListRow key={order.id}>
                    <div className="w-1/2">
                        <span className="capitalize block text-gray-800">{order.title}</span>
                    </div>
                    <div className="w-1/4">
                        <span className="capitalize text-gray-600 text-sm">{formatDate(order.date)}</span>
                    </div>
                    <div className="w-1/4">
                        <span className="capitalize text-gray-600 text-sm">$ {order.total}</span>
                    </div>
                </TableListRow>
            ))
        }
        </TableList>
    );
}