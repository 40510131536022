import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import './SearchForm.css';
import { runSearch } from "../../../api/search";
import { useRef } from "react";

export default function SearchForm({ searchOpen, setSearchOpen}) {
    const term = useRef();

    const search = evt => {
        evt.preventDefault();
        runSearch(term.current.value).then(results => {
            sessionStorage.setItem('search', JSON.stringify(results));
            window.location = '/search';
        });
    };

    return (
        <div className={searchOpen ? `search open`: `search`}>
            <button className="search-close" onClick={() => setSearchOpen(false)}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="search-container container">
                <form className="search-form" onSubmit={search}>
                    <div className="search-form-input-wrap">
                        <input type="text" ref={term} className="appearance-none border border-t-0 border-l-0 border-r-0 rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        <button type="submit">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}