import axios from 'axios';
import config  from './config';

export async function getAuthors() {
    try {
        const req = await axios.get(`${config.URL}authors`);
        return req.data;
    } catch(err) {
        return [];
    }
}

export async function getAuthor(id) {
    try {
        const req = await axios.get(`${config.URL}authors/${id}`);
        return req.data;
    } catch(err) {
        return null;
    }    
}