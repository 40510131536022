import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function OpenSearch({ searchOpen, setSearchOpen }) {
    return (
        <div className="open-search mr-4">
            <button onClick={() => setSearchOpen(!searchOpen)}>
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
    );
}