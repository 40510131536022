import Button from "./UI/layout/Button"
import Grid from "./UI/layout/Grid"

export default function BooksPagination({ previous, next, navigate }) {
    return (
        <Grid className="items-center flex-wrap mt-6">
            {previous !== null ? <Button onClick={() => navigate(previous)}>Previous</Button> : null }
            {next !== null ? <Button onClick={() => navigate(next)} className="ml-auto">Next</Button> : null }
        </Grid>
    )
}