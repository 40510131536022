export default function TableListHeader(props) {
    return (
        <div className="mb-6">
            {props.title && <h3 className="font-bold mb-5 text-center">{props.title}</h3>}
            <div className="flex items-center py-2 justify-evenly px-5 border border-x-0 border-t-0 border-b-slate-200">
                {props.cells.map((cell) => (
                    <div className={`w-1/${cell.size || '2'} text-left`} key={Math.random().toString().replace('.', '')}>
                        <span className="text-xs uppercase text-gray-600 font-bold">
                            {cell.text || ''}
                        </span>
                    </div>    
                ))}
            </div>
        </div>
    )
}