import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import isLoggedIn from '../auth/auth';
import UserDetails from './UserDetails';
import UserRentals from './UserRentals';
import UserOrders from './UserOrders';
import { getRentals, getOrders } from '../api/readers';
import Grid from './UI/layout/Grid';
import Column from './UI/layout/Column';
import Container from './UI/layout/Container';
import './Profile.css';

export default function Profile() {
    const logged = isLoggedIn();
    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        city: '',
        zip: '',
        state: '',
        country: ''
    });
    const [loggedIn, setLoggedIn] = useState(logged);
    const [rentals, setRentals] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        document.title = 'Profile';
        setLoggedIn(logged);
        const reader = JSON.parse(localStorage.getItem('user'));
        setUser(reader);
        getRentals(reader.readerId).then(items => {
            setRentals(items);
        });
        getOrders(reader.readerId).then(ords => {
            setOrders(ords);
        });
    }, [logged]);

    return (
        <>
            {loggedIn ? 
            
             <Container className="w-full">
                <Grid className="w-full container profile-wrap">
                    <Column className="lg:pr-10 user-details">
                        <UserDetails user={user} />
                    </Column>
                    <Column className="rentals-orders">
                        <UserRentals rentals={rentals} />
                        <UserOrders orders={orders} />
                    </Column>
                </Grid>
               
             </Container> 
            
            : <Navigate to={`/login`} replace />}
        </>
    );
}