import Form from './UI/forms/Form';
import Row from './UI/forms/Row';
import Label from './UI/forms/Label';

export default function UserDetails({ user }) {
    return (
        <Form> 
                <Row>
                    <Label>
                        Firstname
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.firstname} readOnly />
                </Row>
                <Row>
                    <Label>
                        Lastname
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.lastname} readOnly />
                </Row>
                <Row>
                    <Label>
                        Email
                    </Label>
                    <input type="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.email} readOnly />
                </Row>
                <Row>
                    <Label>
                        Address
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.address} readOnly />
                </Row>
                <Row>
                    <Label>
                        City
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.city} readOnly />
                </Row>
                <Row>
                    <Label>
                        ZIP
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.zip} readOnly />
                </Row>
                <Row>
                    <Label>
                        State
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.state} readOnly />
                </Row>
                <Row>
                    <Label>
                        Country
                    </Label>
                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={user.country} readOnly />
                </Row>
            </Form>

    );
}