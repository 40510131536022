export default function CartHeader(props) {
    return (
        <div className="cart-header">
            <div className="flex justify-between border-b pb-8">
                    <h1 className="font-semibold lg:text-2xl">Rent or Buy</h1>
                    <h2 className="font-semibold lg:text-2xl">{props.title || ''}</h2>
            </div>
            <div className="flex mt-10 mb-5">
                    <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">Product Details</h3>
                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5">Quantity</h3>
                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5">Price</h3>
                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5">Total</h3>
            </div>
        </div>
    );
}