import Button from "../layout/Button";

export default function CartSummary(props) {
    return (
        <div className="cart-summary">
             <h1 className="font-semibold lg:text-2xl border-b pb-8">Order Summary</h1>
                        <div className="flex justify-between mt-10 mb-5 cart-prices">
                            <span className="font-semibold text-sm uppercase">Items {props.count || '1'}</span>
                            <span className="font-semibold text-sm">$ {props.price || '0.00'}</span>
                        </div>
                        <div className="border-t mt-8">
                            <div className="flex font-semibold justify-between py-6 text-sm uppercase cart-totals">
                                <span>Total cost</span>
                                <span>$ {props.total || '0.00'}</span>
                            </div>
                            <div className="flex justify-between mt-5 cart-actions">
                                <Button onClick={props.handleRent}>Rent</Button>
                                <Button onClick={props.handleBuy}>Buy</Button>
                            </div>      
                        
                    </div>
        </div>
    )
}