import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCategories } from '../api/categories';
import './Categories.css';

export default function Categories() {
     const [categories, setCategories] = useState([]);

    useEffect(() => {
        document.title = 'Categories';
        getCategories().then(resp => {
            setCategories(resp.categories);
        });
    }, []);

    return (

        <div className="categories mt-6 mb-6">
            <div className="container mx-auto">
                {categories.map((category) => (
                    <div className="category rounded shadow-lg px-6 py-4 mb-8" key={category.typeId}>
                        <Link to={`/categories/${category.typeId}`} className="font-bold text-xl block">
                            {category.type_name}
                        </Link>
                    </div>    
                ))}
            </div>
        </div>
    );

}