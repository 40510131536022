import axios from 'axios';
import config  from './config';

export async function didUserVote(userID, bookId) {
     try {
        const req = await axios.get(`${config.URL}reader/${userID}/impressions/${bookId}`);
        return req.data;
    } catch(err) {
        return null;
    }
}

export async function getBookVotes(bookId) {
    try {
        const req = await axios.get(`${config.URL}reader/likes/${bookId}`);
        return req.data;
    } catch(err) {
        return null;
    }    
}

export async function doUserVote(data) {
    try {
        const req = await axios.post(`${config.URL}impressions`, data);
        return req.data;
    } catch(err) {
        return null;
    }        
}