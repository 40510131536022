import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import './Navigation.css';
import Grid from './UI/layout/Grid';
import './Header.css';
import Logo from './UI/layout/Logo';
import { useState } from 'react';

export default function Header( { searchOpen, setSearchOpen } ) {
    const [isNavContainerOpen, setIsNavContainerOpen] = useState(false);
    
    return (
        <header className="AppHeader">
            <nav className="bg-white py-2 md:py-4 border-b-1 border-slate-200">
                <Grid className="px-0 items-center justify-between">
                    <Grid className="justify-between items-center">
                        <Link to={`/`}>
                            <Logo />
                        </Link>
                    </Grid>
                    <Navigation searchOpen={searchOpen} setSearchOpen={setSearchOpen} isNavContainerOpen={isNavContainerOpen} setIsNavContainerOpen={setIsNavContainerOpen} />
                </Grid>
            </nav>
        </header>
    );
}