import './BookListItem.css';
import { Link } from 'react-router-dom';

export default function BookListItem({ item }) {
    return (
        <div className="max-w-lg sm:mb-10 rounded overflow-hidden shadow-lg book">
            <Link to={`/books/${item.id}`}>
            <figure>
                 <img src={item.image} className="w-full" alt={item.name} />
            </figure>
           
            <div className="px-6 py-4">
                <h2 className="font-bold text-xl mb-2">{item.name}</h2>
                <p className="text-gray-700 text-base">{item.author}</p>
            </div>
            </Link>
        </div>
    );
}