import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import isLoggedIn from '../auth/auth';
import Menu from './Menu';
import Grid from './UI/layout/Grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import OpenSearch from './UI/search/OpenSearch';

export default function Navigation({ isNavContainerOpen, setIsNavContainerOpen, searchOpen, setSearchOpen }) {
    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([
            { 
                path: '/', 
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: 'Home'
            },
             { 
                path: '/books', 
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: 'Books'
            },
            { 
                path: '/authors', 
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: 'Authors'
            },
            { 
                path: '/categories', 
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: 'Categories'
            },
            {
                path: isLoggedIn() ? '/profile' : '/login',
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: isLoggedIn() ? 'Profile' : 'Login'
            },
            {
                path: '/register',
                cls: 'text-gray-600 hover:text-gray-700 transition-colors duration-300',
                text: 'Register'
            }
        ])
    }, []);
    return (
        <Grid className="items-center relative">
           <OpenSearch searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
           <Menu setIsNavContainerOpen={setIsNavContainerOpen} isNavContainerOpen={isNavContainerOpen} />  
          <div className={isNavContainerOpen ? "navContainer open fixed px-8 py-8 bg-white" : "navContainer fixed px-8 py-8 bg-white"}>
              <button className="absolute top-2 right-2 z-50" onClick={() => setIsNavContainerOpen(!isNavContainerOpen)}>
                    <FontAwesomeIcon icon={faTimes} />
              </button>  
              <ul className="flex flex-col items-center justify-center">
                    {links.map((link,i) => (
                        <li className="my-2" key={i}><Link onClick={() => setIsNavContainerOpen(!isNavContainerOpen)} to={link.path} className={link.cls}>{link.text}</Link></li>
                    ))}
                    {isLoggedIn() ? <li className="my-2"><Link onClick={() => setIsNavContainerOpen(!isNavContainerOpen)} to={`/logout`} className="text-gray-600 rounded  hover:text-gray-700 transition-colors duration-300">Logout</Link></li> : null }
              </ul>
          </div>  
            
            
        </Grid>
    );
}