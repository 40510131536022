import { Routes, Route } from "react-router-dom";
import Layout from './Layout';
import BookList from "./BookList";
import Book from "./Book";
import Login from "./Login";
import Profile from "./Profile";
import Order from "./Order";
import Logout from "./Logout";
import Register from "./Register";
import Search from "./Search";
import Authors from "./Authors";
import Author from "./Author";
import Categories from "./Categories";
import Category from "./Category";

export default function App() {
    return (
      <>
      <Routes>
          <Route path="/" element={<Layout />}>
               <Route index element={<BookList />} />
               <Route path="/books" element={<BookList />} />
               <Route path="/books/:id" element={<Book />} /> 
               <Route path="/authors" element={<Authors />} />
               <Route path="/authors/:id" element={<Author />} />
               <Route path="/categories" element={<Categories />} />
               <Route path="/categories/:id" element={<Category />} />
               <Route path="/login" element={<Login />} />
               <Route path="/logout" element={<Logout />} /> 
               <Route path="/profile" element={<Profile />} />
               <Route path="/register" element={<Register />} />
               <Route path="/search" element={<Search />} />
               <Route path="/order/:id" element={<Order />} />
          </Route>
      </Routes>
      </>
  );    
}