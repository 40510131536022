import { formatDate } from "../utils/dates";
import TableList from "./UI/layout/TableList";
import TableListHeader from "./UI/layout/TableListHeader";
import TableListRow from "./UI/layout/TableListRow";

export default function UserRentals({ rentals }) {
    const headerCells = [
        {
            size: '2',
            text: 'Book'
        },
        {
            size: '4',
            text: 'Start Date'
        },
        {
            size: '4',
            text: 'End Date'
        }
    ];
    return (
        <TableList>
            <TableListHeader title="Rentals" cells={headerCells} />
            {rentals.map((rent) => (
                <TableListRow key={rent.id}>
                     <div className="w-1/2">
                        <span className="capitalize block text-gray-800">{rent.title}</span>
                    </div>
                    <div className="w-1/4">
                        <span className="capitalize text-gray-600 text-sm">{formatDate(rent.startDate)}</span>
                    </div>
                    <div className="w-1/4">
                        <span className="capitalize text-gray-600 text-sm">{formatDate(rent.endDate)}</span>
                    </div>
                </TableListRow>
                
            ))
            }
        </TableList>

        
    );
}