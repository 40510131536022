import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import './Buttons.css';

export default function Buttons(props) {
    return (
        <div className="impressions">
            <div className="vote">
                <button onClick={props.like}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <span>{props.voteUp || '0'}</span>
            </div>
            <div className="vote">
                <button onClick={props.dislike}>
                    <FontAwesomeIcon icon={faThumbsDown} />
                </button>
                <span>{props.voteDown || '0'}</span>
            </div>
        </div>
    )
}
