import axios from 'axios';
import config  from './config';

export async function getCategories() {
    try {
        const req = await axios.get(`${config.URL}categories`);
        return req.data;
    } catch(err) {
        return [];
    }
}

export async function getCategory(id) {
    try {
        const req = await axios.get(`${config.URL}categories/${id}`);
        return req.data;
    } catch(err) {
        return null;
    }    
}