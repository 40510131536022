import { useMemo } from "react";
import './Search.css';
import { Link } from "react-router-dom";

export default function Search() {
    

    const books = useMemo(() => {
        return sessionStorage.getItem('search') !== null ? JSON.parse(sessionStorage.getItem('search')) : [];
    }, []);

    return (
        <div className="search-results">
            {books.length === 0 ?
            <p className="no-results">
                No results found.
            </p> 
            :
                books.map((book) => (

                    <div className="search-result" key={book.id}>
                        <h4>
                            <Link to={`/books/${book.id}`}>
                                {book.name}
                            </Link>
                        </h4>
                        <div>
                            By {book.author}, Pages: {book.pagecount}, Price: $ {book.price}
                        </div>    
                    </div>    

                ))
            }
        </div>
    );
}