import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBooksByCategory } from '../api/books';
import { getCategory } from '../api/categories';
import BookListItem from './BookListItem';
import './Category.css';

export default function Category() {
    const params = useParams();
    const { id } = params;
    const [books, setBooks] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        getCategory(id).then(data => {
            setCategoryName(data.name);
            document.title = data.name;
        });
        getBooksByCategory(id).then(resp => {
            setBooks(resp);
        });
    }, [id]);

    return (

        <div className="category mt-6 mb-6">
            <h1 className="font-bold text-lg mb-6">
                { categoryName }
            </h1>
            <div className="container mx-auto">
                {books.map((book) => (
                     <BookListItem item={book} key={book.id} />
                ))}
            </div>
        </div>    

    );
}