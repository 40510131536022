import BookListItem from "./BookListItem";
import { getBooks } from '../api/books';
import { useState, useEffect } from 'react';
import BooksPagination from "./BooksPagination";
import Overlay from "./UI/layout/Overlay";

export default function BookList() {
    const [loading, setLoading] = useState(true);
    const [books, setBooks] = useState([]);
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);

    const fetchBooks = (page = '') => {
        setLoading(true);
        getBooks(page).then(data => {
            setBooks(data.books);
            setPrevious(data.previous);
            setNext(data.next);
            setLoading(false);
        });
    };

    useEffect(() => {
        document.title = 'Books';
        fetchBooks();
    }, []);
    
    return (
        <>
        <div className="BookList lg:grid lg:grid-cols-5 lg:gap-4">
            {books.map(book => (
                <BookListItem item={book} key={book.id} />
            ))}
        </div>
        <BooksPagination previous={previous} next={next} navigate={fetchBooks} />
        { loading && <Overlay /> }
        </>
    );
}