export default function CartBody(props) {
    return (
        <div className="cart-body">
            {props.books.map((book) => (
                <div key={Math.random().toString().replace('.','')} className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5">
                            <div className="flex w-2/5">
                                <div className="w-20 item-image">
                                    <img src={`${book.image}`} className="h-24" alt={book.name} />
                                </div>
                                <div className="flex flex-col justify-between ml-4 flex-grow">
                                    <span className="font-bold text-sm">{book.name}</span>
                                </div>
                                
                            </div>
                            <div className="flex justify-center w-1/5 text-center">1</div>
                            <div className="text-center w-1/5 font-semibold text-sm">${book.price}</div>
                            <div className="text-center w-1/5 font-semibold text-sm">${book.price}</div>
                </div>

            ))}
        </div>
    );
}