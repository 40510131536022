import axios from 'axios';
import config  from './config';

export async function create(data) {
    try {
        const req = await axios.post(`${config.URL}rentals`, data);
        return req.data;
    } catch(err) {
        return err;
    }
}