import axios from 'axios';
import config  from './config';

export async function getBooks(page = '') {
    const path = page.toString().length > 0 ? `books?page=${page}` : 'books';
    try {
        const req = await axios.get(`${config.URL}${path}`);
        return req.data;
    } catch(err) {
        return [];
    }
}

export async function getBooksByAuthor(author = '') {
    const path = `books?author=${author}`;
    try {
        const req = await axios.get(`${config.URL}${path}`);
        return req.data.books;
    } catch(err) {
        return [];
    }
}

export async function getBooksByCategory(category = '') {
    const path = `books?category=${category}`;
    try {
        const req = await axios.get(`${config.URL}${path}`);
        return req.data.books;
    } catch(err) {
        return [];
    }   
}

export async function getBook(id) {
    try {
        const req = await axios.get(`${config.URL}book/${id}`);
        return req.data;
    } catch(err) {
        return null;
    }    
}