import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuthors } from '../api/authors';
import './Authors.css';

export default function Authors() {
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        document.title = 'Authors';
        getAuthors().then(resp => {
            setAuthors(resp.authors);
        });
    }, []);

    return (

        <div className="authors mt-6 mb-6">
            <div className="container mx-auto">
                {authors.map((author) => (
                    <div className="author rounded shadow-lg px-6 py-4 mb-8" key={author.authorId}>
                        <Link to={`/authors/${author.authorId}`} className="font-bold text-xl block">
                            {author.author_name} {author.author_surname}
                        </Link>
                    </div>    
                ))}
            </div>
        </div>
    );
}