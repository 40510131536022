import axios from 'axios';
import config  from './config';

export async function runSearch(term = '') {
    try {
        const req = await axios.post(`${config.URL}search`, { s: term });
        return req.data.books;
    } catch(err) {
        return [];
    }
}