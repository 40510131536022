import { useState, useRef } from 'react';
import { register } from '../api/readers';
import { getCountries } from '../api/countries';
import { displayError } from '../utils/errors';
import Container from './UI/layout/Container';
import Form from './UI/forms/Form';
import Row from './UI/forms/Row';
import Label from './UI/forms/Label';
import Submit from './UI/forms/Submit';
import { isEmail, isEmpty } from 'validator';

export default function Register() {

    const firstNameRef = useRef();
    const lastnameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const zipRef = useRef();
    const stateRef = useRef();
    const countryRef = useRef();

    document.title = 'Register';

    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);

    const handleSelectCountry = () => {
        if(countries.length > 0) {
            setCountries([]);
        }
        getCountries().then(list => {
            setCountries(list);
        });
    };

    const handleRegisterForm = evt => {
        evt.preventDefault();
        setErrors({});
        const data = { 
            firstname: firstNameRef.current.value, 
            lastname: lastnameRef.current.value, 
            email: emailRef.current.value, 
            password: passwordRef.current.value, 
            address: addressRef.current.value, 
            city: cityRef.current.value, 
            zip: zipRef.current.value, 
            state: stateRef.current.value, 
            country: countryRef.current.value 
        };

        let validationErrors = {};

        if(isEmpty(data.firstname)) {
            validationErrors.firstname = 'Required field.';
        }
        if(isEmpty(data.lastname)) {
            validationErrors.lastname = 'Required field.';
        }
        if(!isEmail(data.email)) {
            validationErrors.email = 'Invalid email.';
        }
        if(isEmpty(data.password)) {
            validationErrors.password = 'Required field.';
        }
        if(isEmpty(data.address)) {
            validationErrors.address = 'Required field.';
        }
        if(isEmpty(data.city)) {
            validationErrors.city = 'Required field.';
        }
        if(isEmpty(data.zip)) {
            validationErrors.zip = 'Required field.';
        }
        if(isEmpty(data.state)) {
            validationErrors.state = 'Required field.';
        }
        if(isEmpty(data.country)) {
            validationErrors.country = 'Required field.';
        }

        if(Object.keys(validationErrors).length > 0) {
            return setErrors(validationErrors);
        }

        register(data).then(resp => {
            if(resp.errors) {
                return setErrors(resp.errors);
            }
            if(!resp.created) {
                return setErrors({created: 'Oops, please try again later.'});
            }
            return window.location = '/login';
        });
    };

    return(
            <Container className="w-full max-w-screen-md mx-auto">
               <Form onSubmit={handleRegisterForm}> 
                <Row>
                    <Label>
                        Firstname
                    </Label>
                    <input 
                        type="text"
                        ref={firstNameRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'firstname')}
                </Row>
                <Row>
                    <Label>
                        Lastname
                    </Label>
                    <input 
                        type="text"
                        ref={lastnameRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'lastname')}
                </Row>
                <Row>
                    <Label>
                        Email
                    </Label>
                    <input 
                        type="text"
                        ref={emailRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'email')}
                </Row>
                <Row>
                    <Label>
                        Password
                    </Label>
                    <input 
                        type="text"
                        ref={passwordRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'password')}
                </Row>
                <Row>
                    <Label>
                        Address
                    </Label>
                    <input 
                        type="text"
                        ref={addressRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'address')}
                </Row>
                <Row>
                    <Label>
                        City
                    </Label>
                    <input 
                        type="text"
                        ref={cityRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'city')}
                </Row>
                <Row>
                    <Label>
                        ZIP
                    </Label>
                    <input 
                        type="text"
                        ref={zipRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'zip')}
                </Row>
                <Row>
                    <Label>
                        State
                    </Label>
                    <input 
                        type="text"
                        ref={stateRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    {displayError(errors, 'state')}
                </Row>
                <Row>
                    <Label>
                        Country
                    </Label>
                    <select ref={countryRef} onClick={handleSelectCountry} 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="">-- --</option>
                            {countries.map((country) => (
                                <option key={country.code} value={country.name}>{country.name}</option>
                            ))}

                    </select>
                    {displayError(errors, 'country')}
                </Row>
                <Row>
                    <Submit>Register</Submit>
                </Row>
                {displayError(errors, 'created')}
                </Form> 
            </Container> 
    );
}