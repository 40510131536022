import axios from 'axios';
import config  from './config';

export async function login(credentials) {
    try {
        const req = await axios.post(`${config.URL}login`, credentials);
        return req.data;
    } catch(err) {
        return null;
    }
}

export async function register(data) {
    try {
        const req = await axios.post(`${config.URL}register`, data);
        return req.data;
    } catch(err) {
        return err;
    }
}

export async function getRentals(userID) {
    try {
        const req = await axios.get(`${config.URL}reader/${userID}/rentals`);
        return req.data;
    } catch(err) {
        return [];
    }    
}

export async function getOrders(userID) {
    try {
        const req = await axios.get(`${config.URL}reader/${userID}/orders`);
        return req.data;
    } catch(err) {
        return [];
    }       
}